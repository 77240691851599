import React, { useEffect } from 'react';

import LogoColasWithTitle from '../assets/images/logo-text.inline.svg';
import MediaPageHeaderDesign from '../assets/images/mediapage-design-header.inline.svg';
import Layout from '../Layout';

const classNames = require('classnames');
//window.location.replace('/');
// markup
const NotFoundPage = () => {
  useEffect(() => {
    window.location.replace('/'); // redirecting to home page
  }, []);

  return (
    <></>

  );
};

export default NotFoundPage;
